<template>
    <div>
        <!-- main page -->
        <div class="vx-row mb-6" style="width:50%;">
            <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Sales Channel</span>
            </div>
            <div class="vx-col sm:w-2/3 w-full">
                <multiselect
                        class=""
                        v-model="selectSalesChannel"
                        :options="optionSalesChannel"
                        :multiple="false"
                        :allow-empty="false"
                        :group-select="false"
                        :max-height="120"
                        :limit="3"
                        placeholder="Type to search"
                        track-by="id"
                        label="name"
                    >
                    <template slot="singleLabel" slot-scope="dt">
                        <span class="option__desc">
                            <span class="option__title">{{ dt.option.name }}</span>
                        </span>
                    </template>

                    <template slot="option" slot-scope="dt">
                        <div class="option__desc">
                            <span class="option__title">{{ dt.option.name }}</span>
                        </div>
                    </template>

                </multiselect>
            </div>
        </div>
        <vs-tabs ref="sub-tabs" alignment="center">
            <vs-tab label="New SO">
                <div class="con-tab-ejemplo">
                    <datatable :progress="''" :progressRestriction="false" :territory="territory.code" :salesChannel="selectSalesChannel.code" :customerCategory="customerCategory.id" v-on:clickEdit="clickEdit"></datatable>
                </div>
            </vs-tab>
            <vs-tab label="Validated">
                <div class="con-tab-ejemplo">
                    <datatable :progress="'validated'" :progressRestriction="true" :territory="territory.code" :salesChannel="selectSalesChannel.code" :customerCategory="customerCategory.id"></datatable>
                </div>
            </vs-tab>
            <vs-tab label="Approved">
                <div class="con-tab-ejemplo">
                    <datatable :progress="'approved'" :progressRestriction="true" :territory="territory.code" :salesChannel="selectSalesChannel.code" :customerCategory="customerCategory.id"></datatable>
                </div>
            </vs-tab>
            <vs-tab label="Rejected">
                <div class="con-tab-ejemplo">
                    <datatable :progress="'rejected'" :progressRestriction="true" :territory="territory.code" :salesChannel="selectSalesChannel.code" :customerCategory="customerCategory.id" v-on:clickEdit="clickEdit"></datatable>
                </div>
            </vs-tab>
            <vs-tab label="Canceled">
                <div class="con-tab-ejemplo">
                    <datatable :isCancel="1" :territory="territory.code" :salesChannel="selectSalesChannel.code" :customerCategory="customerCategory.id"></datatable>
                </div>
            </vs-tab>
            <vs-tab label="Released">
                <div class="con-tab-ejemplo">
                    <datatable :progress="'released'" :progressRestriction="true" :territory="territory.code" :salesChannel="selectSalesChannel.code" :customerCategory="customerCategory.id"></datatable>
                </div>
            </vs-tab>
            <vs-tab label="Allocated">
                <div class="con-tab-ejemplo">
                    <datatable :progress="'allocated'" :progressRestriction="true" :territory="territory.code" :salesChannel="selectSalesChannel.code" :customerCategory="customerCategory.id"></datatable>
                </div>
            </vs-tab>
            <vs-tab label="|" disabled></vs-tab>
            <vs-tab label="Delivered">
                <div class="con-tab-ejemplo">
                    <datatable :progressWarehouse="'handovering'" :progressRestriction="true" :territory="territory.code" :salesChannel="selectSalesChannel.code" :customerCategory="customerCategory.id"></datatable>
                </div>
            </vs-tab>
            <vs-tab label="Bill">
                <div class="con-tab-ejemplo">
                    <datatable :isBilled="1" :territory="territory.code" :salesChannel="selectSalesChannel.code" :customerCategory="customerCategory.id"></datatable>
                </div>
            </vs-tab>
            <vs-tab label="Closed">
                <div class="con-tab-ejemplo">
                    <datatable :isClosed="1" :territory="territory.code" :salesChannel="selectSalesChannel.code" :customerCategory="customerCategory.id"></datatable>
                </div>
            </vs-tab>
            <!-- <vs-tab label="Planning">
                <div class="con-tab-ejemplo">
                    <datatable :progressWarehouse="'planning'" :progressRestriction="true" :territory="territory.code" :salesChannel="selectSalesChannel.code" :customerCategory="customerCategory.id"></datatable>
                </div>
            </vs-tab>
            <vs-tab label="Picking">
                <div class="con-tab-ejemplo">
                    <datatable :progressWarehouse="'picking'" :progressRestriction="true" :territory="territory.code" :salesChannel="selectSalesChannel.code" :customerCategory="customerCategory.id"></datatable>
                </div>
            </vs-tab>
            <vs-tab label="Packing">
                <div class="con-tab-ejemplo">
                    <datatable :progressWarehouse="'packing'" :progressRestriction="true" :territory="territory.code" :salesChannel="selectSalesChannel.code" :customerCategory="customerCategory.id"></datatable>
                </div>
            </vs-tab>
            -->
        </vs-tabs>
    </div>
</template>


<script>
// import detail from "../component/detail_selected_so.vue";
import datatable from "./datatable.vue";

export default {
    components: {
        // detail,
        datatable
    },
    props: ["territory", "customerCategory"],
    watch: {
        // 
    },
    mounted() {
        this.getOptionSalesChannel();
    },
    data: () => ({
        selectSalesChannel: {},
        optionSalesChannel: [{}]
    }),
    computed: {
        // 
    },
    methods: {
        scrollToTop() {
            window.scrollTo(0,0);
        },

        clickEdit(salesOrder) {
            this.$emit("selectSalesOrder", salesOrder);
            this.$emit("redirectTab", 0);
        },

        getOptionSalesChannel() {
            this.$vs.loading();
            this.$http.get("/api/v1/sales-channel/personal-trader").then(
                resp => {
                    if (resp.code == 200) {
                        this.optionSalesChannel = []
                        this.optionSalesChannel.push({name:'All'})
                        for(var i = 0;i<resp.data.length;i++){
                            this.optionSalesChannel.push(resp.data[i])
                        }
                        this.selectSalesChannel = this.optionSalesChannel[0];
                    } else {
                        this.$vs.notify({
                            title: "Error",
                            text: "Failed to get Sales Channel option",
                            color: "danger",
                            position: "top-right",
                            iconPack: "feather",
                            icon: "icon-check"
                        });
                    }
                    this.$vs.loading.close();
                }
            );
        },
    }
};
</script>

<style>
.core-enter-active {
  transition: all 0.3s ease;
}
.core-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.core-enter, .core-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
.core {
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}
.vs-con-table .vs-con-tbody .vs-table--tbody-table {
  font-size: 12px;
}
.vertical-divider {
  border-left: 1px solid #7367f0;
  min-height: 800px;
  padding: 5px;
}
</style>